@import url('https://fonts.googleapis.com/css2?family=Averia+Gruesa+Libre&display=swap');

@font-face {
  font-family: "DutchMediaeval_Regular";
  src: url("../fonts/DutchMediaeval_Regular.eot");
  src: url("../fonts/DutchMediaeval_Regular.eot?#iefix")format("embedded-opentype"),
      url("../fonts/DutchMediaeval_Regular.woff")format("woff"),
      url("../fonts/DutchMediaeval_Regular.woff2")format("woff2"),
      url("../fonts/DutchMediaeval_Regular.ttf")format("truetype"),
      url("../fonts/DutchMediaeval_Regular.svg")format("svg");
}

@font-face {
  font-family: "DutchMediaeval_Bold";
  src: url("../fonts/DutchMediaeval_Bold.eot");
  src: url("../fonts/DutchMediaeval_Bold.eot?#iefix")format("embedded-opentype"),
      url("../fonts/DutchMediaeval_Bold.woff")format("woff"),
      url("../fonts/DutchMediaeval_Bold.woff2")format("woff2"),
      url("../fonts/DutchMediaeval_Bold.ttf")format("truetype"),
      url("../fonts/DutchMediaeval_Bold.svg")format("svg");
}


:root {
  --questcard: #2f445a;
  --primary: #0c293d;
  --yellow: #fecb36;
  --text-grey: #e2e7ff;
  --purple: #2c2156;
  --modalbtn: #046683;
  --gold: #f2a001;
  --text-light: #9093a0;
}

body {
  font-family: 'DutchMediaeval_Regular' !important;
  background: #0F1322 !important;
  overflow-x: hidden;
}

.homepage_inner {
  overflow: hidden;
}

.header .navbar {
  background: url('../images/header_menu_bg.png') !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  color: #fff;

}

.header .header_logo {
  width: 80px;
  height: 50px;
  position: absolute;
  top: 0px;
}

.header .navbar .container {
  max-width: 90%;
}

.header .navbar-expand-lg .navbar-collapse {
  justify-content: end;
}

.header .navbar-expand-lg .navbar-nav {
  gap: 20px;
}

.header .navbar-expand-lg .navbar-nav a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;

}

.header .navbar-nav a.active {
  border-bottom: 1px solid var(--gold) !important;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 50px;
}

.header .social_icons {
  display: flex;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.header .social_icons a {
  color: #fff !important;
}

.header .social_icons a:hover {
  color: var(--gold) !important;
}

.connect_btn {
  background: transparent !important;
  border: 1px solid var(--gold) !important;
  color: #fff !important;
  font-size: 12px !important;
  padding: 3px 12px !important;

}

.connect_btn:hover {
  background: var(--gold) !important;
  color: #fff !important;

}

.homepage .banner_sec {
  /* background: url("../images/banner1.png"); */
  background: url("../images/bannernewfire.png");

  background-size: 100% 95%;
  min-height: 100vh;
  object-fit: cover;
  position: relative;
  background-repeat: no-repeat;
  padding-left: 0px;
  padding-right: 0px;
}

.custom_container {
  max-width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.homepage .banner_sec .banner_crown {
  width: 260px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.homepage .banner_sec .first_row {
  justify-content: center;
  position: relative;
  z-index: 3;
}

.homepage .banner_sec .fog_bg {
  position: absolute;
  background: url('../images/fog.png');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.homepage .banner_sec .first_row .banner_rippon_sec p {
  margin-top: -20px;
  background: url(../images/rippon.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 400px;
  height: 150px;
  display: flex;
  align-items: center;
  margin: auto;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

.homepage .banner_btn {
  background: url(../images/banner_btn.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10px;
  font-family: DutchMediaeval_Regular;
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  width: 300px;
  transition: all 0.9s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);

}

.homepage .banner_btn:hover {
  transform: scale(1.05, 1.05);
  color: #fff;
}

.homepage .banner_sec .banner_bnt_sec {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.homepage .banner_sec .bottom_sec {
  height: 100px;
}


.homepage .banner_sec .left_img {
  position: absolute;
  bottom: 10px;
  width: 40%;
  left: 0px;
  z-index: 4;
  animation-name: moveInleft;
  animation-duration: 3s;
}

@keyframes moveInleft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.homepage .banner_sec .right_img {
  position: absolute;
  bottom: 10px;
  width: 40%;
  right: 0px;
  z-index: 4;
  animation-name: moveInRight;
  animation-duration: 3s;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }

}

.homepage .banner_sec .grad_bg {

  height: 60px;
  width: 100%;
  position: absolute;
  bottom: -15px;
  z-index: 5;
}

.homepage .second_sec .king_img {
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* min-height: 100vh; */
}

.homepage .banner_sec .back_grad {
  background: linear-gradient(180deg, rgba(15, 19, 33, 0) 0%, #0F1322 32.63%, #0F1322 100%);
  height: 40px;

}

.homepage .third_desc_sec {
  background: url('../images/third.png');
  background-size: 100% 100%;
  margin-top: -60px;
  position: relative;
  z-index: 2;
}

.homepage .third_desc_sec .content_sec {
  width: 85%;
  margin: auto;
  padding: 110px;
  text-align: center;
  color: #fff;
  font-family: DutchMediaeval_Regular;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;

}

.homepage .third_desc_sec .content_sec .end_line {
  width: 200px;
}

.homepage .banner_sec .leftflag_sec {
  position: absolute;
  /* bottom: 0px; */
  bottom: 10px;
  width: 43%;
  z-index: 1;
  animation: tiltImage 5s linear infinite, moveInleft 3s;


}

.homepage .banner_sec .leftflag_sec1 {
  position: absolute;
  bottom: 0px;
  z-index: 1;
  animation: tiltImage 2s linear infinite, moveInleft 3s;
}

.homepage .banner_sec .rightflag_sec {
  position: absolute;
  bottom: 10px;
  width: 43%;
  z-index: 1;
  right: 0px;
  animation: tiltImage 5s linear infinite, moveInRight 3s;
}

.homepage .banner_sec .rightflag_sec1 {
  position: absolute;
  bottom: 0px;
  z-index: 1;
  right: 0px;
  animation: tiltImage 2s linear infinite, moveInRight 3s;
}

.homepage .patternsec .card_sec {
  padding: 110px;
  padding-bottom: 210px;
}

.homepage .charactor_sec {
  background: url('../images/characters_bg_1.png');
  background-size: 100% 100%;
  margin-top: -180px;
  z-index: -1;
  position: relative;
}

.homepage .charactor_sec .content_sec .img_sec {
  display: flex;
  justify-content: center;
}

/* .homepage  .charactor_sec   .content_sec .img_sec .char_img1{
  animation: bannermove 10.5s linear infinite;

} */
.inner_pages .inner_sec {
  padding-top: 100px;
  padding-bottom: 200px;
}

.inner_pages .top_sec .coin_sec .balance_coin {
  font-size: 20px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: end;
  color: #F2C80F;

}

.inner_pages .top_sec {
  width: 75%;
  margin: auto;
}

.inner_pages .top_sec .page_title {
  font-size: 35px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 5px;

}

.inner_pages .top_sec .desc_sec {
  position: relative;
}

.inner_pages .top_sec .coin_sec {
  position: absolute;
  right: -50px;
  bottom: -20px;

}

.inner_pages .tab_sec .tab-content {
  margin-top: 40px;
}

.inner_pages .top_sec .page_desc {
  font-size: 16px;
  font-weight: 200;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  width: 80%;
  margin: auto;

}

.inner_pages .tab_sec {
  width: 85%;
  margin: auto;
}

.inner_pages .tabs_design {
  justify-content: center;
  margin: auto;
  width: 100%;
  border-bottom: 1px solid #a5a5a5;
  gap: 50px;
}

.inner_pages .tabs_design .nav-link {
  border: none !important;
}

.inner_pages .tabs_design .nav-item {
  border-bottom: 1px solid #4B4B4B;
}

.inner_pages .tabs_design .nav-item .nav-link {

  padding-left: 50px;
  padding-right: 50px;

}

.inner_pages .tabs_design .nav-link {
  color: var(--text-light);
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;

}

.inner_pages .tabs_design .nav-link.active {
  color: #F2C80F;
  background: transparent;
  border-bottom: 2px solid #F2C80F !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;

}

.inner_pages .tabs_design .nav-link:hover {
  color: #F2C80F;
  background: transparent;
  border: none;
  border-bottom: 2px solid #F2C80F;
}

.inner_pages .tabs_design .nav-link:focus,
.inner_pages .tabs_design .nav-link:hover {
  border: none !important;
  border-bottom: 2px solid #F2C80F !important;
}

.inner_pages .quest_card {
  background: url('../images/quest_card.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 25px;
  padding-bottom: 70px;
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
  min-width: 550px;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;

}

.inner_pages .quest_card.active_card {
  background: url('../images/active_card.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 25px;
  padding-bottom: 70px;
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
  min-width: 550px;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 55px;

}

.inner_pages .quest_card.active_card .time_duration {
  position: absolute;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-48.5%, -21%);
  top: 14px;
  color: #F2C80F;

}

.inner_pages .quest_card .quest_img {
  min-width: 250px;
  max-width: 250px;
  min-height: 250px;
  max-height: 250px;

}

.inner_pages .card_btn {
  background: url('../images/connect-wallet.png'), #202850;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #f2c80f;
  padding: 5px 15px 5px 15px;
}

.inner_pages .card_btn:hover {

  color: #fff;
}

.inner_pages .quest_card .card_btn {
  position: absolute;
  bottom: 0px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-41.5%, 20%);
  min-width: 25%;
  max-width: 25%;
  height: 50px;
}

.inner_pages .quest_card .rightside .card_title {
  color: #F2C80F !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
}

.inner_pages .quest_card .quest_row {
  align-items: center;
}

.inner_pages .quest_card .rightside .lists {
  color: #F2C80F;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
}

.inner_pages .quest_card .rightside .lists span {
  color: #fff;
  margin-left: 5px;
}

@keyframes bannermove {
  0% {
    margin-left: -2125px;

  }

  50% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 0px;
  }

}

.homepage .charactor_sec .marquee_sec {
  padding-bottom: 25%;
}

.btn.connect-wallet {
  background: url('../images/connect-wallet.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: #F2C80F;
  font-family: DutchMediaeval_Regular;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  transition: all 0.9s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);

}

.btn.connect-wallet:hover {
  color: #fff;
  transform: scale(1.05, 1.05);
}

.footer {
  background: #0F1322;
  padding: 20px;
}

.footer .left_sec p {
  color: #fff;
  margin-bottom: 0px;
}

.footer .center_sec_list {
  display: flex;
  gap: 20px;
}

.footer .center_sec_list a {
  color: #fff;
  text-decoration: none;
}

.footer .center_sec_list {
  color: #fff;
}

.footer .center_sec_list a:hover {
  color: #f2c80f;
}

.footer .end_sec .social_icons {
  display: flex;
  gap: 20px;
  font-size: 24px;
  color: #fff;
  justify-content: center;
}

.footer .end_sec .social_icons svg:hover {
  color: #F2C80F;
  cursor: pointer;
}

.homepage .faq_sec .accordion-button:not(.collapsed)::after {
  background: url('../images/minus.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.homepage .faq_sec .accordion-button::after {
  background: url('../images/plus.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

@keyframes tiltImage {
  0% {
    transform: skewY(5deg);
  }

  50% {
    transform: skewY(-5deg);
  }

  100% {
    transform: skewY(5deg);
  }
}

.homepage .patternsec {
  background: url(../images/pattern_bg.png);
  background-size: 100% 100%;
  margin-top: -80px;

}

.homepage .design_card {
  width: 85%;
  background: url(../images/card_bg.png);
  background-size: 100% 100%;
  padding: 80px;
  color: #fff;
  margin: auto;
  font-family: DutchMediaeval_Regular;
  font-size: 20px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;

}

.homepage .charactor_sec .content_sec {
  text-align: center;
  width: 85%;
  margin: auto;
  padding: 150px;
}

.homepage .charactor_sec .content_sec .sec_title {
  font-family: IM FELL Double Pica SC;
  font-size: 70px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;

}

.homepage .charactor_sec .content_sec .sec_desc {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;

}

.homepage .charactor_sec .content_sec .end_line {
  width: 200px;
}

.homepage .charactor_sec .marquee_sec .chr_img {
  height: 370px;
  width: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin: 15px;
}

.homepage .training_sec .rightsec .sec_title {
  font-family: IM FELL Double Pica SC;
  font-size: 70px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: Left;
  color: #fff;

}

.homepage .training_sec .first_row {
  align-items: center;
}

.homepage .training_sec .rightsec .sec_desc {
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;

}

.homepage .training_sec {
  align-items: center;
  padding-bottom: 40px;
}

.homepage .training_sec .end_line {
  width: 200px;
  margin: auto;
  display: flex;
}

.homepage .roadmap_sec .top_sec {
  text-align: center;
}

.homepage .roadmap_sec .top_sec .title {
  font-size: 55px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: center;
  color: #F2C80F;
  margin-bottom: 40px;

}

.homepage .faq_sec .container {
  max-width: 90%;
}

.inner_container {
  max-width: 90% !important;
}

.homepage .charactor_sec .inner_container {
  max-width: 100% !important;
}

.homepage .faq_sec .left_sec .sec_title {
  color: #F2C80F;
  font-size: 55px;
  font-weight: 400;
  line-height: 84px;
  letter-spacing: 0em;
  text-align: left;

}

.homepage .faq_sec .accordion-button {
  background: #151725;
  color: #fff;
  border: none !important;
}

.homepage .faq_sec .accordion-button:focus {
  box-shadow: none;
}

.homepage .faq_sec .accordion-item {
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 20px;
}

.homepage .faq_sec .accordion-body {
  background: #151725;
  color: #fff;
  border: none !important;
  font-family: "DutchMediaeval_Regular";
}

.homepage .banner_sec .anim_fog {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  Left: 0;
  top: 0;
  background: url('../images/fogss.png') repeat-x;
  background-size: 200% auto;
  background-position: bottom;
  animation: fogAnim 10s;
  animation-timing-function: Linear;
  animation-iteration-count: infinite;
}

@keyframes fogAnim {
  0% {
    background-position: -200% bottom
  }

  100% {
    background-position: 0% bottom
  }
}

.homepage .faq_sec {
  padding-top: 30px;
  padding-bottom: 30px;
}

.homepage .team_sec .team_card {
  position: relative;
}

.homepage .team_sec .team_card .team_card_bg {
  position: absolute;
  background: url(../images/storecard.png);
  background-size: 100% 100%;
  z-index: 1;
  width: 100%;
  height: 400px;
}

.homepage .team_sec .team_card {
  min-height: 400px;
  max-height: 400px;
  /* min-width: 270px;
    max-width: 270px; */
}

.homepage .team_sec .team_card .team_img {
  min-height: 335px;
  max-height: 335px;
  margin-top: 25px;
  width: 100%;

  object-fit: cover;
}

.homepage .team_sec .title_top {
  text-align: center;
  padding-bottom: 40px;
}

.homepage .team_sec .title_top .sec_title {
  font-size: 55px;
  font-weight: 400;
  line-height: 84px;
  letter-spacing: 0em;
  text-align: center;
  color: #F2C80F;

}

.homepage .team_sec .card_sec {
  margin-bottom: 40px;
}

.homepage .team_sec .end_line_sec {
  text-align: center;
  margin-bottom: 20px;
}

/* .homepage .tower_sec{
    background: url(../images/pagetower.png);
    background-size: 100% 100%;
    
  } */
.homepage .tower_sec .inner_container {
  max-width: 100% !important;
}

.homepage .tower_sec .content_sec .leftside .sec_title {
  font-size: 35px;
  font-weight: 400;
  line-height: 63px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  font-family: "DutchMediaeval_Bold" !important;

}

.homepage .tower_sec .content_sec .leftside .sec_desc {
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;

}

.homepage .faq_sec .left_sec .faq_img {
  position: relative;
}

.homepage .faq_sec .left_sec .faq_img::after {
  content: "";
  background: radial-gradient(circle, rgba(240, 207, 48, 0.8225884103641457) 0%, rgba(240, 204, 48, 0) 71%);
  height: 150px;
  width: 150px;
  animation: zoom-in-zoom-out 3s ease infinite;
  position: absolute;
  right: 4px;
  bottom: -10px;
  opacity: 0.5;
}


.homepage .faq_sec .glow_sec {
  position: relative;
  text-align: end;
}

/* .homepage .faq_sec .left_sec .glow_sec{
    animation: gloow 3s infinite alternate
  } */
@keyframes gloow {
  from {
    background: #f2c80f54;
    box-shadow: 0 0 10px -10px #F2C80F;
  }

  to {
    background: #F2C80F;
    box-shadow: 0 0 10px 10px #F2C80F;
  }
}

.homepage .faq_sec .left_sec .img_glow {
  background: radial-gradient(circle, rgba(240, 207, 48, 0.8225884103641457) 0%, rgba(240, 204, 48, 0) 71%);
  height: 150px;
  width: 150px;
  animation: zoom-in-zoom-out 3s ease infinite;
  position: absolute;
  right: 5%;
  bottom: -10px;
  opacity: 0.5;

}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes glowing3 {
  0% {
    background-color: #bc881b;
    ;
    box-shadow: 0 0 50px #bc881b;
  }

  50% {
    background-color: #bc881b;
    box-shadow: 0 0 100px #db990e;
  }

  100% {
    background-color: #bc881b;
    box-shadow: 0 0 50px #e59f0a;
  }
}

@keyframes animateglow {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.3;
  }
}

.homepage .tower_sec .bottom_sec {
  position: relative;
}

.homepage .tower_sec .bottom_sec .content_sec {
  position: sticky;
  top: 0px;

}

.homepage .tower_sec .bottom_sec .content_sec .leftside {
  position: absolute;
  top: 65px;
  width: 50%;
  padding: 80px;
}

.homepage .roadmap_sec .mapsec .sec_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;

}

.homepage .roadmap_sec .mapsec .sec_desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin-bottom: 100px;

}

.homepage .roadmap_sec .full_map {
  background: url(../images/roadmap_pap.png);
  background-size: 100% 90%;

  background-repeat: no-repeat;
}

.homepage .roadmap_sec .top_sec .first_row {
  margin-bottom: 50px;
  padding-top: 70px;
}

.homepage .roadmap_sec .top_sec .second_row {
  height: 800px;
  padding-top: 8%;
  padding-right: 55px;
}

.homepage .roadmap_sec .top_sec .third_row {
  padding-bottom: 10%;
  padding-left: 100px;
}

.homepage .roadmap_sec .down_sec .first_row {
  padding-bottom: 20%;

}

.loadmore_btn {
  border: 1px solid var(--gold) !important;
  /* background: url('../images/loadmore.png');
  background-size: 100% 100%;
  background-repeat: no-repeat; */
  padding: 5px 25px 5px 25px !important;
  color: #fff !important;
  font-size: 12px !important;
}

.loadmore_btn:hover {
  color: #fff !important;
  background: var(--gold) !important;

}

.quest_pages .loadmore_btn_sec {
  text-align: center;
}

.mining_pages .mint_form .loadmore_btn {
  height: 50px;
  width: 135px;
}

.form-control {
  border: 1px solid #F5F5F5 !important;
  background: transparent !important;
  height: 45px;
  border-radius: 10px !important;
  color: #fff !important;
}

.form-control::placeholder {
  color: #757575 !important;
}

.quest_pages .form_sec label {
  color: #fff;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

}

.inner_pages .mint_form {
  padding-top: 100px;
}

.btn.btn-minting {
  background: url('../images/connect-wallet.png'), #2B335D;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 12px 25px 12px 25px;
  color:
    #F2C80F;
  font-size: 12px;
}

.btn.btn-minting:hover {
  color: #fff;
}

.mining_pages .btn_sec {
  text-align: center;
  padding-top: 50px;
}

.mining_pages .mint_form .upload_title {
  font-size: 22px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 5px;
}

.mining_pages .mint_form .upload_type {
  font-size: 16px;
  font-weight: 200;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;

}

.mining_pages .mint_form .leftsec .upload_frame {
  background: linear-gradient(0deg, #2B335D, #2B335D),
    linear-gradient(0deg, #858585, #858585);
  border: 1px solid #858585;
  text-align: center;
  color: #fff;
  padding: 25px;
  border-radius: 5px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mining_pages .mint_form .leftsec .upload_frame .file_upload_img {
  margin-bottom: 10px;
}

.mining_pages .mint_form .leftsec .upload_frame .first {

  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;

}

.mining_pages .mint_form .leftsec .upload_frame .second {

  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  margin-bottom: 0px;

}

.mining_pages .mint_form .leftsec .upload_frame .third {
  font-size: 14px;
  font-weight: 200;
  line-height: 29px;
  letter-spacing: 0em;
  color: #AEAEAE;

}

.form_sec .upload_row {
  align-items: end;
}

.form-control:focus {
  box-shadow: none;
}

.quest_pages .quest_rippon_sec {
  background: url(../images/quest_rippon.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 70px;
  margin: auto;

}

.quest_pages .quest_rippon_sec p {
  font-size: 28px;
  margin-bottom: 0px;
  margin-top: 5px;
}





.quest_pages .quest_char_card {
  position: relative;
  min-width: 330px;
  max-width: 330px;
  max-height: 430px;
  min-height: 430px;
  margin: auto;
}

.quest_pages .quest_char_card .quest_char_img {
  min-width: 330px;
  max-width: 330px;
  max-height: 400px;
  min-height: 400px;
  object-fit: cover;
  padding: 8px;
}

.quest_pages .quest_char_card .quest_dframe {
  position: absolute;
  background: url('../images/quest_char_card.png');
  background-size: 100% 100%;
  height: 100%;
  width: 100%;

}

.quest_pages .quest_char_card .btn_sec .card_btn {
  position: absolute;
  z-index: 3;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-48.5%, -21%);
  left: 50%;
  height: 50px;
  padding: 5px 30px 5px 30px;
}


.btn-menu {
  background: transparent !important;
}

/* fog */
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  overflow-x: hidden;
}

#foglayer_01,
#foglayer_02,
#foglayer_03 {
  height: 100%;
  position: absolute;
  width: 200%;
}

#foglayer_01 .image01,
#foglayer_01 .image02,
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}

#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
}

#foglayer_02,
#foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
}

/* ---------- Moving Fog ---------- */
/*
    'size: cover' || 'size: 100%'; results remain the same
    'attachment: scroll' can be added or removed; results remain the same
    'attachment: fixed' causing unexpected results in Chrome
    'repeat-x' || 'no-repeat'; results remain the same
  */
#foglayer_01 .image01,
#foglayer_01 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog1.png") center center/cover no-repeat transparent;
}

#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog2.png") center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .5;
  }

  40% {
    opacity: .28;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

@-moz-keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .5;
  }

  40% {
    opacity: .28;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

@-o-keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .5;
  }

  40% {
    opacity: .28;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

@keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .5;
  }

  40% {
    opacity: .28;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% {
    opacity: .5;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .5;
  }
}

@-moz-keyframes foglayer_02_opacity {
  0% {
    opacity: .5;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .5;
  }
}

@-o-keyframes foglayer_02_opacity {
  0% {
    opacity: .5;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .5;
  }
}

@keyframes foglayer_02_opacity {
  0% {
    opacity: .5;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .5;
  }
}

/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% {
    opacity: .8
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@-moz-keyframes foglayer_03_opacity {
  0% {
    opacity: .8
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@-o-keyframes foglayer_03_opacity {
  0% {
    opacity: .8
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@keyframes foglayer_03_opacity {
  0% {
    opacity: .8;
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@-moz-keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@-o-keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {

  #foglayer_01 .image01,
  #foglayer_01 .image02,
  #foglayer_02 .image01,
  #foglayer_02 .image02,
  #foglayer_03 .image01,
  #foglayer_03 .image02 {
    width: 100%;
  }
}

/* fog */
@media only screen and (max-width:575px) {

  .quest_pages .quest_char_card {
    min-width: unset;
    max-width: unset;
    width: 100%;

  }

  .quest_pages .quest_char_card .quest_char_img {
    min-width: unset;
    max-width: unset;
    width: 100%;

  }

  .quest_pages .quest_char_card .btn_sec .card_btn {
    font-size: 12px;
  }

  .homepage .banner_sec {
    background: url("../images/bannernewfire.png");
    background-size: cover;
    background-position: center;

  }

  .homepage .banner_sec .right_img {
    width: 45%;
  }

  .homepage .banner_sec .left_img {
    width: 45%;
  }

  .store_pages .store_card_sec {
    margin-top: 0px !important;
  }

  .store_pages .store_line_sec .frameborderstyle.bottom {
    margin-top: -70px !important;
  }
}

@media only screen and (max-width:767px) {
  .inner_pages .quest_card {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }

  .store_pages .store_card_sec {
    margin-top: 0px !important;
  }

  .store_pages .store_line_sec .frameborderstyle.bottom {
    margin-top: -70px !important;
  }

}

@media only screen and (max-width:991px) {
  .inner_pages .top_sec .page_title {
    font-size: 30px;
  }

  .inner_pages .quest_card {
    text-align: center;
  }

}

@media only screen and (max-width:1199px) {
  .inner_pages .tabs_design {
    gap: 20px;
  }
}

@media only screen and (min-width:1200px) and (max-width:1350px) {
  .inner_pages .quest_card {
    min-width: 525px;
    max-width: 525px;
  }
}


.reveal_bg {
  background: url(../images/unreveal_bg.png);
  background-size: 100% 100%;
  position: relative;
  bottom: 90px;
  /* position: absolute; */
  z-index: 3;
  left: 0;
  width: 100%;
  height: 125px;
  padding: 5px 30px 5px 30px;
  /* bottom: -10px; */
}

.text_reveal_head {
  color: #0A0A0A;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}

.icon_reveal {
  max-width: 20px;
  max-height: 20px;
}

.icon_tetx_reveal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textgreen_reveal {
  font-size: 14px;
  color: #1A9806;
  font-weight: 600;

}

.text_reveal {
  font-size: 14px;
  font-weight: 600;

  color: #0A0A0A;

}

.reveal_text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -20%);
}

.overlay_icon {
  position: absolute;
  right: 20px;
  top: 20px;
  max-width: 40px;
}

.tooltip_des {
  background-color: #fff;
  border-radius: 30px;
  opacity: 1 !important;
}

#tooltip>.tooltip-inner {
  background-color: #fff;
  background: #fff;
  color: #000;
  border-radius: 30px;
  min-width: 280px;
  padding: 10px 0px;
}

#tooltip>.tooltip-arrow::before {
  border-top-color: #fff;
}



.quest_pages .quest_char_card.store_bg_card .quest_dframe {
  position: absolute;
  background: url('../images/store_card_bg.png');
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}

.quest_pages .quest_char_card.store_bg_card .quest_char_img {
  margin-top: 10px;
}

.reveal_bg.reveal_bg_store {
  background: url(../images/store_bg_text.png);
  bottom: 115px;
  /* position: absolute; */
  left: 13px;
  width: calc(100% - 46px);
  height: 100px;
  /* bottom: -10px; */
  margin: 8px;
  border-radius: 0px 0px 10px 10px;

}

.reveal_bg.reveal_bg_store .reveal_text {
  transform: translate(-50%, -50%) !important;
}

/* .quest_pages .quest_char_card.store_bg_card
{
min-height: 400px !important;
max-height: 400px !important;
} */

.quest_pages .quest_char_card.store_bg_card .quest_char_img {
  min-height: 375px !important;
  max-height: 375px !important;
}

.quest_pages .quest_char_card.store_bg_card .btn_sec .card_btn {
  transform: translate(-48.5%, -33%) !important;
  z-index: 4;
  min-width: 130px !important;
}

.store_title_pos {
  position: absolute;
  color: #fff;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -3px);
}

.page_privacy_bg {
  background: url(../images/privacy_pattern.png);
  background-size: cover;

}

.privacy_text_white {
  color: #fff;
  font-size: 13px !important;
}



.card.quest_card_style {
  background: var(--questcard);
  border-radius: 20px;
}

.card.quest_card_style .first_row {
  padding: 20px;
  align-items: center;
}

.card.quest_card_style .first_row .rightside .title_card {
  color: #fff;
  font-size: 18px;
}

.card.quest_card_style .first_row .rightside .catg {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
}

.card.quest_card_style .first_row .rightside .count {
  color: #fff;
  font-size: 12px;
  margin-bottom: 5px;
}

.card.quest_card_style .first_row .rightside .count img {
  margin-right: 5px;
}

.card.quest_card_style .first_row .rightside .token_label {
  background: var(--yellow);
  padding: 5px;
  color: #000;
  border-radius: 5px;
  text-align: center;
  width: 70%;
  margin-top: 20px;

}

/* .card.quest_card_style .first_row .leftside .img_sec{
  min-width: 180px;
  max-width: 180px;
  min-height: 180px;
  max-height: 180px;
  background: #000;
  padding: 10px;
} */
.card.quest_card_style .first_row .leftside .quest_pic {
  min-width: 180px;
  max-width: 180px;
  min-height: 180px;
  max-height: 180px;
  object-fit: cover;
  border-radius: 25px;
  border: 8px solid #000;
}

.card.quest_card_style .bottom_link {
  background-color: var(--primary);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
  text-align: center;
}

.card.quest_card_style .bottom_link a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
}

.card.quest_card_style .first_row .rightside .time_duration {
  background: rgb(238, 0, 147);
  padding: 5px;
  padding-top: 0PX;
  padding-bottom: 0px;
  color: #fff;
  font-size: 14PX;
  width: 30%;
  text-align: center;
  border-radius: 5PX;
}

.card.quest_card_style .bottom_link .final_link {
  color: var(--yellow);
}

.card.char_card_style {
  background: transparent;
  border-radius: 20px;
  min-height: 400px;
  max-height: 400px;
  width: 100%;
  position: relative;
}

.card.char_card_style .char_img {
  min-height: 400px;
  max-height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;

}

.card.char_card_style .detail_sec {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  bottom: 0px;
}

.card.char_card_style .detail_sec .title {
  color: var(--yellow);
  font-size: 8px;
  margin-bottom: 0px;
  text-align: center;
}

.card.char_card_style .detail_sec .title_desc {
  color: #fff;
  font-size: 12px;
  margin-bottom: 0px;
  text-align: center;
}

.card.char_card_style .reveal_sec {
  position: absolute;
  width: 80%;
  bottom: 30px;
  text-align: center;
  background: var(--yellow);
  border-radius: 6px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 4px;
  /* box-shadow: 0 0 10px 5px var(--yellow),
 0 0 10px 7px var(--yellow),
 0 0 15px 10px var(--yellow),
 0 0 10px 15px var(--yellow); */

}

.card.char_card_style .reveal_sec .reveal_btn {
  color: #000;
  text-decoration: none;
  text-align: center;

}

.quest_detail .recent_quest {
  margin-top: 100px;
}

.quest_detail .recent_quest .page_title {
  color: #fff;
}

.quest_detail .recent_quest .card_rows {
  display: flex;
  justify-content: center;

}

.card.store_card_style {
  background: transparent;
  position: relative;
  min-height: 400px;
  max-height: 400px;
  width: 100%;
}

.card.store_card_style .store_frame {
  background: url('../images/storecard.png');
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  z-index: 3;
}

.card.store_card_style .title {
  position: absolute;
  color: #fff;
  top: 25px;
  z-index: 4;
  transform: translate(-50%, -50%);
  left: 50%;
}

.card.store_card_style .desc {
  color: #fff;
  position: absolute;
  z-index: 4;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: 10%;
  font-size: 10px;
}

.card.store_card_style .price {
  color: #fff;
  position: absolute;
  z-index: 4;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: -1%;
  margin-bottom: 5px;
  font-size: 15px;
}

.card.store_card_style .store_img {
  min-height: 310px;
  max-height: 310px;
  object-fit: cover;
  width: 100%;
  margin-top: 50px;
}

.card.store_card_style .price .store_coin {
  margin-right: 10px;

}

.store_pages .store_card_sec {
  width: 85%;
  margin: auto;
  margin-top: -80px;
}

.store_pages .store_line_sec {
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 10px;
}

.store_pages .store_line_sec .frameborderstyle {
  width: 100%;
}

.store_pages .store_line_sec .frameborderstyle.bottom {
  margin-top: -100px;
}

.quest_detailcard_style .rightside .det_title {
  color: var(--yellow);
  margin-bottom: 5px;
}

.quest_detailcard_style .rightside .det_desc {
  color: #fff;
  margin-bottom: 5px;
}

.card.quest_detailcard_style .first_row .leftside .quest_pic {
  min-width: 320px;
  max-width: 320px;
  min-height: 320px;
  max-height: 320px;

}

.card.quest_detailcard_style .first_row .leftside .img_sec {
  min-width: 320px;
  max-width: 320px;
  min-height: 320px;
  max-height: 320px;
  margin: auto;
}

.detail_card_sec {
  width: 80%;
  margin: auto;
}

.card.quest_detailcard_style .first_row .rightside .title_card {
  font-size: 16px;
}

.card.quest_detailcard_style .first_row .rightside .token_label {
  width: 45%;
}

.card.char_card_style.create_card .create_img {
  min-height: 430px;
  max-height: 430px;
  object-fit: cover;
  border-radius: 15px;
  width: 100%;
}

.card.char_card_style.create_card {
  min-height: 430px;
  max-height: 430px;
}

.card.char_card_style.create_card .btn_sec {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.card.char_card_style.create_card .create_desc {
  position: absolute;
  bottom: 0px;
  left: 20px;
  color: #fff;
}

.quest_pages,
.store_pages {
  background: url('../images/quest_bg.png');
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
}

.modal-dialog .modal-content {
  background: var(--purple);
  border: 1px solid #a8a8a8;
}

.modal-dialog .modal-content .modal-header {
  border-bottom: none;
}

.modal-dialog .modal-content .bg_pattern {
  background: url('../images/modal_pattern.png');
  height: 100%;
  width: 100%;
  background-size: 100% 100px;
  position: absolute;
  background-repeat: no-repeat;
  top: 5px;
}

.modal-dialog .modal-content .modal-header .modal-title {
  color: #fff;
  text-align: center;
  width: 100%;
  line-height: 20px;
}

.modal-dialog .modal-content .modal-header .modal-title p {
  margin-bottom: 0px;
  font-size: 16px;
}

.modal-dialog .modal-content .modal_btn {
  background: var(--modalbtn);
  padding: 8px;
  font-size: 10px;
  width: 100px;
  border: 1px solid transparent;
  border-radius: 10px;

}

.revealmodal .modal-dialog .modal-content .btn_sec {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.modal-dialog .modal-content .modal_btn:hover {
  border: 1px solid var(--yellow);
  background: var(--yellow);
}

.form-control:focus {
  box-shadow: none !important;
}

.mintingmodal .modal-dialog .modal-content .modal-body .mining_nft_img {
  max-width: 190px;
  min-width: 190px;
  max-height: 250px;
  min-height: 250px;
  object-fit: cover;

}

.mintingmodal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
  margin-top: 30px;
}

.mintingmodal .modal-dialog .modal-content .modal-body {
  /* max-width: 80%;
  margin: auto; */
}

.mintingmodal .modal-dialog .modal-content .modal-body .detail_sec .lists {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
}

.mintingmodal .modal-dialog .modal-content .modal-body .detail_sec .lists p {
  color: #fff;
}

.mintingmodal .modal-dialog .modal-content .modal-body .detail_sec {
  margin: auto;
  width: 80%;
}

.mintingmodal .modal-dialog .modal-content .modal-footer .bg_pattern_bottom {
  background: url('../images/modal_pattern.png');
  height: 100%;
  width: 100%;
  background-size: 100% 100px;
  position: absolute;
  background-repeat: no-repeat;
  bottom: 0px;
  transform: rotate(180deg);
  left: 0px;
}

.mintingmodal .modal-dialog .modal-content .modal-footer {
  border-top: none;
  position: relative;
}

.mintingmodal .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 35px;
}

.banner_btn {
  background: url('../images/banner_btn.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10px;
  font-family: DutchMediaeval_Regular;
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  width: 300px;
  transition: all 0.9s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);
}

.banner_btn:hover {
  transform: scale(1.05, 1.05);
  color: #fff;
}

.mintingmodal .modal-dialog .modal-content .modal-footer .btn_sec {
  z-index: 4;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.mintingmodal .modal-dialog .modal-content .modal-footer .btn_sec .banner_btn {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
}

.modal-dialog .close_btn {
  width: 0;
  height: 0;
  border-top: 50px solid #98989838;
  border-left: 50px solid transparent;
  position: absolute;
  right: 0px;
  z-index: 5;
}

.modal-dialog .close_btn .close_text {
  color: #fff;
  top: -45px;
  bottom: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}

.mintingmodal .modal-dialog .detail_sec .counter_sec {
  display: flex;
  gap: 20px;
}

.mintingmodal .modal-dialog .detail_sec .counter_sec .count_btn {
  cursor: pointer;
}

.mintingmodal .modal-dialog .modal-content {
  border: none;
}

.cms_pages .cms_content_sec .heading {

  color: var(--yellow);
  margin-bottom: 5px;
}

.cms_pages .cms_content_sec .desc {
  color: #fff;
}

.cms_pages .cms_content_sec {
  padding-top: 50px;
}

.cms_pages .cms_content_sec ul {
  list-style: none;
}


@media only screen and (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse {
    background: var(--purple);
    position: absolute;
    width: 300px;
    top: 50px;
    text-align: center;
    padding: 20px;
    right: 0px;
    min-height: 100vh;
    overflow: auto;
  }

  .header .social_icons {
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

  }

  .header .navbar-toggler-icon {
    background-image: url('../images/menu_burger.png') !important;
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 25px;
    width: 30px;
  }

  .header .navbar-toggler,
  .header .navbar-toggler:focus {
    box-shadow: none;
  }
}


@media only screen and (max-width: 575px) {
  .inner_pages .top_sec .coin_sec {
    position: unset;
  }

  .inner_pages .top_sec .coin_sec .balance_coin {
    text-align: center;
  }

  .homepage .banner_sec .banner_crown {
    width: 120px;
    padding-top: 70px;
  }

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 140px;

  }

  .homepage .banner_sec .first_row .banner_rippon_sec p {
    width: 275px;
    font-size: 18px;
    line-height: 25px;
  }

  .card.quest_detailcard_style .first_row .leftside .quest_pic {
    width: 100%;
    min-width: unset;
    max-width: unset;
    min-height: 250px;
    max-height: 250px;
  }

  .card.quest_detailcard_style .first_row .leftside .img_sec {
    width: 100%;
    min-width: unset;
    max-width: unset;
    min-height: 250px;
    max-height: 250px;
  }

  .homepage .banner_btn {
    width: 220px;
    font-size: 18px;
  }

  .homepage .third_desc_sec .content_sec {
    width: 100%;
    padding: 40px;
  }

  .homepage .patternsec .card_sec {
    padding: 0px;
    padding-top: 30%;
    padding-bottom: 80%;
  }

  .homepage .patternsec .design_card p {
    line-height: 25px;
    font-size: 18px;
  }

  .homepage .design_card {
    width: 100%;
    padding: 40px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background: url('../images/sm_design_card.png');
    background-size: 100% 100%;
  }

  .homepage .faq_sec .left_sec .sec_title {
    font-size: 28px;
    line-height: 40px;
  }

  .homepage .roadmap_sec .top_sec .first_row {
    margin-bottom: 15px;
  }

  .homepage .roadmap_sec .top_sec .second_row {
    height: unset;
    padding-top: 0px;
  }

  .homepage .roadmap_sec .top_sec .third_row {
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .homepage .roadmap_sec .down_sec .first_row {
    padding-bottom: 0px;
  }

  .homepage .roadmap_sec .full_map {
    background: url('../images/roadmap_paper.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 50px;
    background-position-x: -15px;
    margin-top: -80px;
  }
  .homepage .roadmap_sec .top_sec .second_row{
    padding-right: 0px;
  }
  .homepage .roadmap_sec .mapsec .sec_desc {
    margin-bottom: 25px;
  }

  .homepage .faq_sec .left_sec .faq_img {
    position: relative;
    width: 250px;
    margin-bottom: 20px;
  }

  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    position: unset;
    width: 75%;
    padding: 10px;
  }

  .homepage .tower_sec .content_sec .leftside .sec_desc {
    font-size: 16px;
    line-height: 25px;
  }

  .homepage .charactor_sec .marquee_sec .chr_img {
    height: 270px;
    width: 200px;
  }

  .homepage .charactor_sec .content_sec {
    width: 100%;
    padding: 50px;
  }

  .homepage .charactor_sec .content_sec .sec_title {
    font-size: 40px;
    line-height: 40px;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }

  .footer .center_sec_list {
    display: unset;
    margin-bottom: 10px;
  }

  .footer .end_sec .social_icons {
    padding-top: 10px;
    justify-content: start;
  }
}

@media only screen and (min-width:576px) and (max-width:767px) {
  .homepage .banner_sec .banner_crown {
    width: 180px;
    padding-top: 55px;
  }

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 250px;

  }

  .homepage .banner_sec .first_row .banner_rippon_sec p {
    width: 250px;
    font-size: 20px;
  }

  .homepage .banner_btn {
    width: 250px;
    line-height: 30px;
    font-size: 20px;
  }

  .homepage .banner_sec {
    background-size: cover;
    background-position: center;
  }

  .homepage .banner_sec .left_img,
  .homepage .banner_sec .right_img {
    width: 45%;
  }

  .homepage .design_card {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    background: url('../images/tab_design_card.png');
    background-size: 100% 100%;
  }

  .homepage .patternsec .card_sec {
    padding: 40px;
    padding-top: 150px;
    padding-bottom: 210px;
  }

  .homepage .third_desc_sec .content_sec {
    width: 100%;
  }

  .homepage .charactor_sec .marquee_sec .chr_img {
    height: 330px;
    width: 260px;
  }

  .homepage .charactor_sec .content_sec {
    width: 100%;
    padding: 20px;
    padding-top: 120px;

  }

  .homepage .roadmap_sec .top_sec .first_row {
    margin-bottom: 15px;
  }

  .homepage .roadmap_sec .top_sec .second_row {
    height: unset;
    padding-top: 0px;
    padding-right: 0px;
  }

  .homepage .roadmap_sec .top_sec .third_row {
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .homepage .roadmap_sec .down_sec .first_row {
    padding-bottom: 0px;
  }

  .homepage .roadmap_sec .full_map {

      background: url('../images/roadmap_paper.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 50px;
      background-position-x: -15px;
      margin-top: -80px;
  
  } 

  .homepage .roadmap_sec .mapsec .sec_desc {
    margin-bottom: 25px;
  }

  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    position: unset;
    width: 60%;
    padding: 80px;
  }

  .homepage .tower_sec .content_sec .leftside .sec_desc {
    font-size: 16px;
    line-height: 27px;
  }

  .homepage .tower_sec .content_sec .leftside .sec_title {
    font-size: 28px;
    line-height: 40px;
  }

  .footer .left_sec p {
    text-align: center;
  }

  .footer .center_sec_list {
    justify-content: center;
  }

  .footer .end_sec .social_icons {
    padding-top: 10px;
  }

  .inner_pages .quest_detail .top_sec .coin_sec {
    position: unset;
  }

  .inner_pages .quest_detail .top_sec .coin_sec .balance_coin {
    text-align: center;

  }
}

@media only screen and (max-width:767px) {
  .card.quest_card_style .leftside .img_sec {
    text-align: center;
  }

  .card.quest_card_style .first_row .rightside .token_label {
    width: 100%;
  }

  .card.quest_card_style .first_row .rightside .title_card {
    text-align: center;
    margin-top: 20px;
  }

  .card.quest_card_style .first_row .rightside .time_duration {
    width: 100%;
    margin-top: 20px;
  }

  .quest_detail .detail_card_sec {
    width: 100%;
    margin: auto;
  }

  .quest_detail .inner_pages .coin_sec {
    position: unset;
  }
}

@media only screen and (min-width:768px) and (max-width:991px) {

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 400px;
  }

  .homepage .banner_sec .banner_crown {
    width: 185px;
    padding-top: 75px;
  }

  .homepage .banner_sec .first_row .banner_rippon_sec p {
    width: 400px;
    height: 130px;
    font-size: 20px;
    line-height: 30px;
  }

  .homepage .banner_btn {

    font-size: 20px;
    line-height: 32px;
    width: 250px;
  }

  .homepage .third_desc_sec .content_sec {
    width: 100%;

    padding: 85px;
  }

  .homepage .patternsec .card_sec {
    padding: 0px;
    padding-top: 100px;
  }

  .homepage .design_card {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .homepage .third_desc_sec .card_sec .design_card p {
    line-height: 30px;
    font-size: 20px;
  }

  .homepage .roadmap_sec .full_map {

    background: url('../images/roadmap_paper.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 50px;
    background-position-x: -15px;
    margin-top: -80px;

} 

  .homepage .roadmap_sec .mapsec .sec_desc {
    margin-bottom: 20px;
  }

  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    top: 0px;
    width: 65%;
  }

  .homepage .tower_sec .content_sec .leftside .sec_desc {
    font-size: 18px;
    line-height: 25px;
  }

  .homepage .patternsec {
    padding-bottom: 25%;
  }

  .homepage .charactor_sec .content_sec {
    width: 100%;
    padding: 40px;
  }

  .homepage .charactor_sec .marquee_sec .chr_img {
    height: 300px;
    width: 230px;
  }

  .homepage .roadmap_sec .top_sec .first_row {
    margin-bottom: 15px;
  }

  .homepage .roadmap_sec .top_sec .second_row {
    height: unset;
    padding-top: 0px;
    padding-right: 0px;
  }

  .homepage .roadmap_sec .top_sec .third_row {
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .homepage .roadmap_sec .down_sec .first_row {
    padding-bottom: 0px;
  }

  .homepage .faq_sec .glow_sec {
    text-align: center;
  }

  .homepage .faq_sec .left_sec .img_glow {
    right: 16%;
  }

  .footer .left_sec p {
    text-align: center;
  }

  .footer .center_sec_list {
    justify-content: center;
  }

  .footer .end_sec .social_icons {
    padding-top: 10px;
  }

  .inner_pages .quest_detail .top_sec .coin_sec {
    position: unset;
  }

  .inner_pages .quest_detail .top_sec .coin_sec .balance_coin {
    text-align: center;

  }
  .homepage .banner_sec .right_img,.homepage .banner_sec .left_img{
    width: 50%;
  }
}

@media only screen and (min-width:991px) and (max-width:1199px) {

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 370px;
  }

  .homepage .banner_sec .banner_crown {
    width: 185px;
    padding-top: 75px;
  }

  .homepage .banner_sec .first_row .banner_rippon_sec p {
    width: 400px;
    height: 130px;
    font-size: 20px;
    line-height: 30px;
  }

  .homepage .banner_btn {

    font-size: 20px;
    line-height: 32px;
    width: 250px;
  }

  .homepage .patternsec .card_sec {
    padding-left: 20px;
    padding-right: 20px;
  }

  .homepage .design_card {
    width: 100%;
  }

  .homepage .charactor_sec .marquee_sec .chr_img {
    height: 340px;
    width: 270px;
  }

  .homepage .roadmap_sec .top_sec .second_row {
    padding-top: 45px;
    height: 625px;
    padding-left: 30px;
  }

  .homepage .roadmap_sec .top_sec .third_row {
    padding-bottom: 10%;
  }

  .homepage .roadmap_sec .down_sec .first_row {
    padding-bottom: 50%;
    padding-top: 15%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    line-height: 30px;
  }

  .homepage .faq_sec .left_sec .faq_img {
    width: 350px;
  }

  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    top: 0px;
  }

  .homepage .tower_sec .content_sec .leftside .sec_desc {
    font-size: 18px;
    line-height: 30px;
  }

  .inner_pages .quest_detail .top_sec .coin_sec {
    position: unset;
  }

  .inner_pages .quest_detail .top_sec .coin_sec .balance_coin {
    text-align: center;

  }

  .card.quest_detailcard_style .first_row .leftside .img_sec,
  .card.quest_detailcard_style .first_row .leftside .quest_pic {
    min-width: 250px;
    max-width: 250px;
    min-height: 250px;
    max-height: 250px;
  }
}

@media only screen and (min-width:1200px) and (max-width:1350px) {
  .homepage .banner_sec .banner_crown {
    width: 180px;
    padding-top: 65px;
  }

  .homepage .banner_sec .first_row .banner_rippon_sec p {
    width: 300px;
    height: 150px;
    font-size: 20px;
    line-height: 25px;
  }

  .homepage .banner_btn {
    font-size: 21px;
    line-height: 30px;
    width: 250px;
  }

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 460px;
  }

  .homepage .design_card {
    width: 100%;
  }

  .homepage .faq_sec .left_sec .faq_img {
    width: 400px;
  }

  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    top: 0px;
    width: 65%;
  }

}

@media only screen and (max-width: 1350px) {
  .inner_pages .tab_sec {
    width: 100%;
  }

  .store_pages .store_card_sec,
  .store_pages .store_line_sec {
    width: 100%;
  }

  .store_pages .store_card_sec {
    width: 85%;
    margin-top: -40px;
  }

}

@media only screen and (min-width: 1350px) and (max-width: 1400px) {

  .homepage .banner_sec .banner_crown {
    width: 200px;
    padding-top: 60px;
  }

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 550px;
  }
}

@media only screen and (min-width: 1351px) {
  .homepage .banner_sec .banner_crown {
    width: 330px;
    padding-top: 70px;
  }
}

@media only screen and (min-width:1200px) {
  .quest_pages.quest_detail .top_sec {
    position: relative;
  }

  .inner_pages .quest_pages.quest_detail .top_sec .desc_sec {
    position: unset;
  }

  .inner_pages .quest_pages.quest_detail .top_sec .coin_sec {
    top: 0px;
    right: -30px;
  }
}

.quest_pages.quest_detail {
  background: url('../images/questdetail_bg.png');
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
}
@media only screen and (min-width:1700px) {
  .homepage .roadmap_sec .mapsec .sec_desc{
    font-size: 26px;
    line-height: 30px !important;
  }
  .card.quest_card_style .first_row .rightside .title_card{
    font-size: 23px;
  }
  .card.quest_card_style .first_row .rightside .catg {
    font-size: 20px;
  }
  .card.quest_card_style .first_row .rightside .token_label {
    font-size: 18px;
  }
  .card.quest_card_style .bottom_link a {

    font-size: 20px;
}
.card.quest_card_style .first_row .rightside .count {
  font-size: 18px;
}
.quest_detailcard_style .rightside .det_title {
  font-size: 20px;
}
.quest_detailcard_style .rightside .det_desc {
font-size: 18px;
}
.quest_detail .recent_quest .page_title {
  font-size: 24px;
}
.inner_pages .top_sec .page_desc {
  font-size: 18px;
}
}


.scrollanimchar {
  display: flex;
  flex-direction: row;
  gap: 10px;

}
@media only screen and (min-width:1200px) {

.homepage .charactor_sec .marquee_sec {
  padding-bottom: 15%;
} 
.homepage .training_sec {
  margin-top: -90px;
}
.homepage .charactor_sec .content_sec {
  padding-bottom: 60px;
}
}